import { addAreas, deleteArea, getAreas, updateArea } from "../../../services/Area.service";

export const AREA_ADD = "AREA_ADD";
export const AREA_ADD_SUCCESS = "AREA_ADD_SUCCESS";
export const AREA_ADD_FAIL = "AREA_ADD_FAIL";

export const GET_ALL_AREA = "GET_ALL_AREA";
export const GET_ALL_AREA_SUCCESS = "GET_ALL_AREA_SUCCESS";
export const GET_ALL_AREA_FAIL = "GET_ALL_AREA_FAIL";

export const UPDATE_AREA_BY_ID = "UPDATE_AREA_BY_ID";
export const UPDATE_AREA_BY_ID_SUCCESS = "UPDATE_AREA_BY_ID_SUCCESS";
export const UPDATE_AREA_BY_ID_FAIL = "UPDATE_AREA_BY_ID_FAIL";

export const SET_AREA_OBJ = "SET_AREA_OBJ";
export const SET_AREA_OBJ_SUCCESS = "SET_AREA_OBJ_SUCCESS";
export const SET_AREA_OBJ_FAIL = "SET_AREA_OBJ_FAIL";

export const GET_AREA_BY_ID = "GET_AREA_BY_ID";
export const GET_AREA_BY_ID_SUCCESS = "GET_AREA_BY_ID_SUCCESS";
export const GET_AREA_BY_ID_FAIL = "GET_AREA_BY_ID_FAIL";

export const DELETE_AREA_BY_ID = "DELETE_AREA_BY_ID";
export const DELETE_AREA_BY_ID_SUCCESS = "DELETE_AREA_BY_ID_SUCCESS";
export const DELETE_AREA_BY_ID_FAIL = "DELETE_AREA_BY_ID_FAIL";

export const AREAADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: AREA_ADD });
    let { data: response } = await addAreas(formData);
    if (response) {
      dispatch(AREAGET())
      dispatch({
        type: AREA_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: AREA_ADD_FAIL, payload: err });
  }
};

export const AREAGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_AREA });
    let { data: response } = await getAreas(formData);
    if (response) {

      dispatch({
        type: GET_ALL_AREA_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: AREA_ADD_FAIL, payload: err });
  }
};

export const SETAREAOBJ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_AREA_OBJ });
    if (formData) {
      dispatch({
        type: SET_AREA_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_AREA_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_AREA_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const AREAUPDATE = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_AREA_BY_ID });
    let { data: response } = await updateArea(formData, id);
    if (response) {
      console.log(response);
      dispatch({
        type: UPDATE_AREA_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(AREAGET());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_AREA_BY_ID_FAIL, payload: err });
  }
};

export const AREADELETE = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_AREA_BY_ID });
    let { data: response } = await deleteArea(id);
    if (response) {
      console.log(response, "response");
      dispatch({
        type: DELETE_AREA_BY_ID_SUCCESS,
        payload: response
      });
      dispatch(AREAGET());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_AREA_BY_ID_FAIL, payload: err });
  }
};