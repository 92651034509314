import { combineReducers } from "redux";

import { authReducer } from "./auth/auth.reducer";
import { BrandReducer } from "./Brand/Brand.reducer";
import { TaxReducer } from "./Tax/Tax.reducer";
import { BannerReducer } from "./Banner/Branner.reducer";
import { userReducer } from "./Users/users.reducer";
import { AttributeReducer } from "./Attribute/Attribute.reducer";
import { CategoryReducer } from "./Category/Category.reducer";
import { ProductReducer } from "./Product/Product.reducer";
import { stateReducer } from "./State/States.reducer";
import { cityReducer } from "./City/City.reducer";
import { areaReducer } from "./Area/Area.reducer";
import { wareHouseReducer } from "./WareHouse/WareHouse.reducer";
import { GalleryReducer } from "./Gallery/Gallery.reducer";
import { blogCategoryReducer } from "./blogCategory/BlogCategory.reducer";
import { blogReducer } from "./Blog/Blog.reducer";
import { tagReducer } from "./Tag/Tag.reducer";
import { logoReducer } from "./Logo/Logo.reducer";
import { contactInfoReducer } from "./ContactInfo/ContactInfo.reducer";
import { faqReducer } from "./Faq/Faq.reducer";
import { privacyPolicyReducer } from "./PrivacyPolicy/PrivacyPolicy.reducer";
import { paymentGatewayReducer } from "./PaymentGateway/PaymentGateway.reducer";
import { NewsLetterReducer } from "./NewsLetter/NewsLetter.reducer";
import { NotificationReducer } from "./Notification/Notification.reducer";
import { CurrencyReducer } from "./Currency/Currency.reducer";
import { couponReducer } from "./Coupon/Coupon.reducer"
import { mailTemplateReducer } from "./MailTemplate/MailTepmlate.reducer";
import { testimonialReducer } from "./Testimonial/Testimonial.reducer";
import { shippingReducer } from "./Shipping/Shipping.reducer";
import { teamReducer } from "./Team/Team.reducer";
import { seoReducer } from "./Seo/Seo.reducer";


const RootReducer = combineReducers({
  auth: authReducer,
  brand: BrandReducer,
  taxes: TaxReducer,
  banner: BannerReducer,
  users: userReducer,
  states: stateReducer,
  city: cityReducer,
  area: areaReducer,
  coupon: couponReducer,
  warehouse: wareHouseReducer,
  attribute: AttributeReducer,
  category: CategoryReducer,
  product: ProductReducer,
  gallery: GalleryReducer,
  blogCategory: blogCategoryReducer,
  blog: blogReducer,
  tag: tagReducer,
  logo: logoReducer,
  contactInfo: contactInfoReducer,
  faq: faqReducer,
  privacyPolicy: privacyPolicyReducer,
  paymentGateway: paymentGatewayReducer,
  newsLetter: NewsLetterReducer,
  notification: NotificationReducer,
  currency: CurrencyReducer,
  mailTemplate:mailTemplateReducer,
  testimonial:testimonialReducer,
  shipping:shippingReducer,
  team:teamReducer,
  seo:seoReducer
});

export default RootReducer;
