import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";
import * as BLOG from "../../actions/Blog/Blog.actions";

const initialState = {
  blogs: null,
  blogObj: null,
  loading: false,
  error: null,
};

export const blogReducer = (state = initialState, action) => {
  // console.log(action, "reduscer");
  switch (action.type) {
    case BLOG.BLOG_ADD:
      return {
        ...state,
        loading: true,
      };
    case BLOG.BLOG_ADD_SUCCESS:
      toastSuccess(action.payload);
      // console.log(action, "reducer");

      return {
        ...state,
        loading: false,
      };

    case BLOG.BLOG_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BLOG.GET_ALL_BLOGS:
      return {
        ...state,
        loading: true,
      };
    case BLOG.GET_ALL_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        blogs: action.payload.data,
      };
    case BLOG.GET_ALL_BLOGS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BLOG.DELETE_BLOG_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case BLOG.DELETE_BLOG_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case BLOG.DELETE_BLOG_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BLOG.SET_BLOG_OBJ:
      return {
        ...state,
        loading: true,
      };
    case BLOG.SET_BLOG_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        BLOGObj: action.payload.data,
        loading: false,
        error: null,
      };
    case BLOG.SET_BLOG_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case BLOG.UPDATE_BLOG_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case BLOG.UPDATE_BLOG_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case BLOG.UPDATE_BLOG_BY_ID_FAIL:
      toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
