import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import { SETSTATEOBJ, STATEADD, STATEGET, STATEUPDATE } from "../../../redux/actions/State/States.actions";
import { CITYADD, CITYUPDATE, SETCITYOBJ } from "../../../redux/actions/City/City.actions";
function AddCity({ makeChange }) {
    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [status, setStatus] = useState(generalModelStatuses.APPROVED);
    const [stateArr, setStateArr] = useState([]);
    const [stateId, setStateId] = useState("");
    const [stateObj, setStateObj] = useState({});
    const statesObj = useSelector((state) => state.states.statesObj);
    const cityObj = useSelector((state) => state.city.citiesObj);
    const statesArr = useSelector((state) => state.states.states);
    const handleAddCategory = () => {
        let obj = {
            name,
            stateId: stateId,
            status,
        };
        console.log(obj, "category obj");
        if (cityObj?._id) {
            dispatch(CITYUPDATE(cityObj._id, obj));
            dispatch(SETCITYOBJ(null))
        } else {
            dispatch(CITYADD(obj));
        }
    };

    useEffect(() => {
        if (cityObj) {
            setName(cityObj?.name);
            setStatus(cityObj?.status);
            setStateId(cityObj.stateId)
            if (stateArr && stateArr.length > 0) {
                console.log(...stateArr.filter(el => el._id == cityObj.stateId), "stateArr.filter(el => el._id == cityObj.stateId)")
                setStateObj(...stateArr.filter(el => el._id == cityObj.stateId).map(el => { return { ...el, label: el.name, value: el._id } }))
            }
        }

        // return () => {
        //     dispatch(SETSTATEOBJ(null));
        // };
    }, [cityObj, stateArr]);



    useEffect(() => {
        dispatch(STATEGET());
    }, [])

    useEffect(() => {
        if (statesArr) {
            setStateArr(statesArr)
        }
    }, [statesArr]);



    return (
        <div className={makeChange ? "makeChange" : ""}>
            <form className="form row">
                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                        Name <span className="red">*</span>
                    </label>
                    <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                </div>
                <div className="col-12 col-md-12">
                    <label>
                        State <span className="red">*</span>
                    </label>
                    <Select
                        options={stateArr.map(el => { return { ...el, value: el._id, label: el.name } })}
                        placeholder="Select from options"
                        defaultInputValue={stateId} value={stateObj}
                        onChange={(e) => {
                            console.log(e, "asd")
                            setStateId(e.value);
                            setStateObj(e);
                        }}
                    />
                </div>
                <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
                    <label className="blue-1 fs-12">Status</label>
                    <div className="d-flex">
                        <div className="form-check form-check-inline d-flex align-items-center">
                            <input className="form-check-input" checked={status == generalModelStatuses.APPROVED} onClick={() => setStatus(generalModelStatuses.APPROVED)} type="radio" />
                            <label className="form-check-label fs-14" htmlFor="category-Radio1">
                                Active
                            </label>
                        </div>
                        <div className="form-check form-check-inline d-flex align-items-center">
                            <input className="form-check-input" type="radio" checked={status == generalModelStatuses.DECLINED} onClick={() => setStatus(generalModelStatuses.DECLINED)} />
                            <label className="form-check-label fs-14" htmlFor="category-Radio2">
                                Inactive
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <CustomButton btntype="button" ClickEvent={handleAddCategory} iconName="fa-solid fa-check" btnName="Save" isBtn small={makeChange ? true : false} />
                </div>
            </form>
        </div>
    );
}

export default AddCity;
