import { addGallery, getGallery, updateGallery } from "../../../services/Gallery.service";

export const GALLERY_ADD = "GALLERY_ADD";
export const GALLERY_ADD_SUCCESS = "GALLERY_ADD_SUCCESS";
export const GALLERY_ADD_FAIL = "GALLERY_ADD_FAIL";

export const GET_ALL_GALLERY = "GET_ALL_GALLERY";
export const GET_ALL_GALLERY_SUCCESS = "GET_ALL_GALLERY_SUCCESS";
export const GET_ALL_GALLERY_FAIL = "GET_ALL_GALLERY_FAIL";

export const UPDATE_GALLERY_BY_ID = "UPDATE_GALLERY_BY_ID";
export const UPDATE_GALLERY_BY_ID_SUCCESS = "UPDATE_GALLERY_BY_ID_SUCCESS";
export const UPDATE_GALLERY_BY_ID_FAIL = "UPDATE_GALLERY_BY_ID_FAIL";


export const GALLERYADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GALLERY_ADD });
    let { data: response } = await addGallery(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: GALLERY_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: GALLERY_ADD_FAIL, payload: err });
  }
};

export const GALLERYGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_GALLERY });
    let { data: response } = await getGallery(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: GET_ALL_GALLERY_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_ALL_GALLERY_FAIL, payload: err });
  }
};



export const GALLERYUPDATE = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_GALLERY_BY_ID });
    let { data: response } = await updateGallery(formData, id);
    if (response) {
      console.log(response);
      dispatch({
        type: UPDATE_GALLERY_BY_ID_SUCCESS,
        payload: response.message
      });
      dispatch(GALLERYGET())
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_GALLERY_BY_ID_FAIL, payload: err });
  }
};
