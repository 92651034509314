import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";
import * as NOTIFICATION from "../../actions/notification/Notification.action";

const initialState = {
  NotificationArr: null,
  NotificationObj: null,
  loading: false,
  error: null,
};

export const NotificationReducer = (state = initialState, action) => {
  // console.log(action, "reduscer");
  switch (action.type) {
    case NOTIFICATION.NOTIFICATION_ADD:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION.NOTIFICATION_ADD_SUCCESS:
      toastSuccess(action.payload);
      // console.log(action, "reducer");

      return {
        ...state,
        loading: false,
      };

    case NOTIFICATION.NOTIFICATION_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case NOTIFICATION.GET_ALL_NOTIFICATION:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION.GET_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        NotificationArr: action.payload.data,
      };
    case NOTIFICATION.GET_ALL_NOTIFICATION_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case NOTIFICATION.DELETE_NOTIFICATION_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION.DELETE_NOTIFICATION_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case NOTIFICATION.DELETE_NOTIFICATION_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case NOTIFICATION.SET_NOTIFICATION_OBJ:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION.SET_NOTIFICATION_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        NotificationObj: action.payload.data,
        loading: false,
        error: null,
      };
    case NOTIFICATION.SET_NOTIFICATION_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NOTIFICATION.UPDATE_NOTIFICATION_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION.UPDATE_NOTIFICATION_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case NOTIFICATION.UPDATE_NOTIFICATION_BY_ID_FAIL:
      toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
