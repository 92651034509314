export const generalModelStatuses = {
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  PENDING: "PENDING",
};

export const ORDER_STATUS = {
  PENDING: "PENDING",
  CONFIRMED:"CONFIRMED",
  ORDERED: "ORDERED",
  PROCESSED: "PROCESSED",
  DISPATCHED: "DISPATCHED",
  DELIVERED: "DELIVERED",
  CANCELLED: "CANCELLED",
  R: "R",
};

export const EMAIL_TEMPLATE_TYPES = {
// ORDER_INVOICE_TEMPLATE:"ORDER_INVOICE_TEMPLATE",
ORDER_PENDING_TEMPLATE:"ORDER_PENDING_TEMPLATE",
ORDER_CONFIRMED_TEMPLATE:"ORDER_CONFIRMED_TEMPLATE",
// ORDER_DECLINED_TEMPLATE:"ORDER_DECLINED_TEMPLATE",
// PAID_PAYMENT_TEMPLATE:"PAID_PAYMENT_TEMPLATE",
ORDER_COMPLETED_TEMPLATE:"ORDER_COMPLETED_TEMPLATE",
// DELIVERY_PROCESS_TEMPLATE:"DELIVERY_PROCESS_TEMPLATE",
// REFUND_PENDING_TEMPLATE:"REFUND_PENDING_TEMPLATE",
// REFUND_CONFIRMED_TEMPLATE:"REFUND_CONFIRMED_TEMPLATE",
// REFUND_DECLINED_TEMPLATE:"REFUND_DECLINED_TEMPLATE",
// REFUND_MONEY_PAID_TEMPLATE:"REFUND_MONEY_PAID_TEMPLATE",
// REFUND_MONEY_PENDING_TEMPLATE:"REFUND_MONEY_PENDING_TEMPLATE",
// REFUND_COMPLETED_TEMPLATE:"REFUND_COMPLETED_TEMPLATE",
// REFUND_PROCESS_TEMPLATE:"REFUND_PROCESS_TEMPLATE",
// GIFT_CARD_TEMPLATE:"GIFT_CARD_TEMPLATE",
// REVIEW_EMAIL_TEMPLATE:"REVIEW_EMAIL_TEMPLATE",
NEWSLETTER_EMAIL_TEMPLATE:"NEWSLETTER_EMAIL_TEMPLATE",
// WALLET_EMAIL_TEMPLATE:"WALLET_EMAIL_TEMPLATE",
// ORDER_EMAIL_TEMPLATE:"ORDER_EMAIL_TEMPLATE",
REGISTER_EMAIL_TEMPLATE:"REGISTER_EMAIL_TEMPLATE",
NOTIFICATION_EMAIL_TEMPLATE:"NOTIFICATION_EMAIL_TEMPLATE",
// SUPPORT_TICKET_EMAIL_TEMPLATE:"SUPPORT_TICKET_EMAIL_TEMPLATE",
VERIFICATION_EMAIL_TEMPLATE:"VERIFICATION_EMAIL_TEMPLATE",
// PRODUCT_REVIEW_EMAIL_TEMPLATE:"PRODUCT_REVIEW_EMAIL_TEMPLATE",
// PRODUCT_DISABLE_EMAIL_TEMPLATE:"PRODUCT_DISABLE_EMAIL_TEMPLATE",
// PRODUCT_REVIEW_APPROVE_EMAIL_TEMPLATE:"PRODUCT_REVIEW_APPROVE_EMAIL_TEMPLATE",
// PRODUCT_UPDATE_EMAIL_TEMPLATE:"PRODUCT_UPDATE_EMAIL_TEMPLATE",
// WITHDRAW_REQUEST_EMAIL_TEMPLATE:"WITHDRAW_REQUEST_EMAIL_TEMPLATE",
PASSWORD_RESET:"PASSWORD_RESET"
};
