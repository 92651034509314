import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import { images } from "../../Images/Images";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { BANNERGet, SetBANNERObj } from "../../../redux/actions/Banner/Banner.actions";
import { generateFilePath } from "../../Utility/utils";
import { GALLERYGET, GALLERYUPDATE } from "../../../redux/actions/Gallery/Gallery.actions";

export default function ViewGallery() {
    const dispatch = useDispatch();

    const gallleryArr = useSelector((state) => state.gallery.gallery);

    useEffect(() => {
        dispatch(GALLERYGET());
    }, []);

    const brand_columns = [
        {
            name: "ID",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "20%",
        },
        {
            name: "Image",
            grow: 0,
            width: "20%",
            cell: (row) => <img height="84px" width="56px" alt={row.name} src={generateFilePath(row.imageUrl)} />,
        },

        {
            name: "Action",
            width: "20%",
            cell: (row) => <ActionIcon isRedirected={true} onDeleteClick={() => dispatch(GALLERYUPDATE({}, row._id))} deletePath="/Gallery" remove Uniquekey={row._id} />,
        },
    ];




    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h5 className="blue-1 m-0">Gallery Image List</h5>
                                <div className="d-flex align-items-center gap-3">
                                    <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW GALLERY IMAGE" path="/Gallery/Create" small roundedPill />
                                    <SearchBox extraClass="bg-white" />
                                </div>
                            </div>
                            <DashboardTable>
                                <DataTable columns={brand_columns} data={gallleryArr && gallleryArr.length > 0 ? gallleryArr : []} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
