import React, { useMemo } from "react";
import { BrowserRouter as Router ,useNavigate} from "react-router-dom";
import AuthorizedRoutes from "./AuthorizedRoutes";
import { useSelector } from "react-redux";
import UnauthorizedRoutes from "./UnauthorizedRoutes";
import { axiosApiInstance } from "../App";
import { logoutUser } from "../redux/actions/auth/auth.actions";
import { refreshToken } from "../services/users.service";
import axios from "axios";
import { toastError } from "../utils/toastUtils";
import jwtDecode from "jwt-decode";
export default function RootRouter() {
  const authObj = useSelector((state) => state.auth);

  let token = useSelector((state) => state.auth.token)

  useMemo(() => {
    axiosApiInstance.interceptors.request.use(
      async (config) => {
        // console.log(token)
        if (token) {
          config.headers['authorization'] = 'Bearer ' + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
      },
      error => {
        console.log(error)
        Promise.reject(error)
      });
    axiosApiInstance.interceptors.response.use(
      (res) => {
        // Add configurations here
        return res;
      },
      async (err) => {
        console.log("INterceptor error")
        let {config,response} = err;
        if(response && response.status == 401){
       

            let decode =await jwtDecode(token);
console.log(decode,"tokentoken")
              let refreshTokenResponse = await refreshToken(decode.user);

              if(refreshTokenResponse.data && refreshTokenResponse.data.success){
                  let refreshToken = refreshTokenResponse.data.token;
                  localStorage.setItem("token", refreshToken);
                  await  new Promise(resolve => {
                    config.headers["authorization"] = "Bearer " + refreshToken;
                    console.log(config,"configconfigconfig")
                      resolve(axios(config))
                    })
              }

        }
        await logoutUser()

        return Promise.reject(err);
      }
    );
  }, [])



  return <Router>{authObj?.isAuthorized ? <AuthorizedRoutes /> : <UnauthorizedRoutes />}</Router>;
}
