import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch, Tooltip } from "@mui/material";
import { images } from "../Images/Images";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import { AddModal, EditModal } from "../Utility/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  PRODUCTDelete,
  PRODUCTGet,
  PRODUCTUpdate,
  SetPRODUCTObj,
} from "../../redux/actions/Product/Product.actions";
import { generateFilePath } from "../Utility/utils";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import {
  approveProductsInBulk,
  deleteProductsInBulk,
  productCloneApi,
  unapproveProductsInBulk,
} from "../../services/product.service";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import SearchBox from "../Utility/SearchBox";
import { getProducts } from "../../services/product.service";

function ProductList() {
  const dispatch = useDispatch();
  const params = useParams();
  const productArr = useSelector((state) => state.product.products);
  const productObject = useSelector(
    (state) => state.product.ProductPaginationObj
  );

  const [displayProductArr, setDisplayProductArr] = useState([]);
  // const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [query, setQuery] = useState("");

  const handleGetProducts = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      // console.log(queryOf,"queryOfqueryOfqueryOfqueryOf")
      // dispatch(PRODUCTGet(queryOf));

      let { data: get } = await getProducts(queryOf);
      console.log(get, "get =====");
      if (get.success) {
if(get.data){

  setDisplayProductArr([
    ...get?.data.map((el) => {
      el.checked = false;
      return el;
    }),
  ]);
}else{
  setDisplayProductArr([])
}
        setTotalPages(get?.totalPages);
        setTotalCount(get?.totalCount);
        setPage(get?.page);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGetProducts();
  }, [page, perPage, query]);

  const [displayButtons, setDisplayButtons] = useState(false);

  // useEffect(() => {
  //   // console.log(productArr, "productArr2134");
  // if (productArr && productArr.length >= 0) {
  //   setDisplayProductArr([
  //     ...productArr.map((el) => {
  //       el.checked = false;
  //       return el;
  //     }),
  //   ]);
  // }
  // if (productObject) {
  //   setTotalPages(productObject?.totalPages);
  //   setTotalCount(productObject?.totalCount);
  //   setPage(productObject?.page);
  // }
  // }, [productArr ,productObject]);

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };




  const handleEdit = (row) => {
    // dispatch(SetPRODUCTObj(row));
  };

  const handleDeleteById = (id) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      dispatch(PRODUCTDelete(id));
    }
  };

  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalData, setModalData] = useState({});
  const [ModalBox, setModalBox] = useState(false);

  const handleStatusActuve = (id, status) => {
    try {
      console.log(id, "final Obj");
      let obj = {
        active: status,
      };
      dispatch(PRODUCTUpdate(obj, id));
      handleGetProducts();
    } catch (err) {
      toastError(err);
    }
  };

  const handleCheckProduct = (row, index, e) => {
    try {
      let tempArr = displayProductArr.map((el) => {
        if (row._id == el._id) {
          el.checked = !el.checked;
        }
        return el;
      });
      handleGetProductCheckedStatus(tempArr);
      setDisplayProductArr([...tempArr]);
      console.log(index, row, e.target.checked);
    } catch (err) {
      toastError(err);
    }
  };

  const handleGetProductCheckedStatus = (tempArr) => {
    try {
      if (tempArr.some((el) => el.checked == true)) {
        setDisplayButtons(true);
      } else {
        setDisplayButtons(false);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const navigate = useNavigate();

  const handleCloneProduct = async (product) => {
    try {
      if (product) {
        try {
          delete product._id;
          let { data: res } = await productCloneApi(product);
          if (res.data) {
            toastSuccess(res.message);
            dispatch(SetPRODUCTObj(res.data));
            navigate("/Product/Update-Product?slug="+res.data._id);
          }
        } catch (error) {
          toastError(error);
        }
      }
    } catch (err) {
      toastError(err);
    }
  };
  const handleDeleteSelected = async () => {
    try {
      let selectedArr = displayProductArr
        .filter((el) => el.checked)
        .map((el) => {
          let obj = {
            productId: el._id,
          };
          return obj;
        });
      if (!selectedArr.length > 0) {
        toastError("Please select atleast one product to perform this action");
        return;
      }
      let { data: res } = await deleteProductsInBulk(selectedArr);
      if (res.message) {
        toastSuccess(res.message);
        handleGetProducts();
      }
    } catch (e) {
      toastError(e);
    }
  };

  const handleSetSelectedAsActive = async () => {
    try {
      let selectedArr = displayProductArr
        .filter((el) => el.checked)
        .map((el) => {
          let obj = {
            productId: el._id,
          };
          return obj;
        });
      if (!selectedArr.length > 0) {
        toastError("Please select atleast one product to perform this action");
        return;
      }
      let { data: res } = await approveProductsInBulk(selectedArr);
      console.log(res, "response");
      if (res.message) {
        toastSuccess(res.message);
        handleGetProducts();
      }
    } catch (e) {
      toastError(e);
    }
  };
  const handleSetSelectedAsInActive = async () => {
    try {
      let selectedArr = displayProductArr
        .filter((el) => el.checked)
        .map((el) => {
          let obj = {
            productId: el._id,
          };
          return obj;
        });
      if (!selectedArr.length > 0) {
        toastError("Please select atleast one product to perform this action");
        return;
      }

      let { data: res } = await unapproveProductsInBulk(selectedArr);
      if (res.message) {
        toastSuccess(res.message);
        handleGetProducts();
      }
    } catch (e) {
      toastError(e);
    }
  };

  const handleClearSelection = () => {
    let tempArr = displayProductArr.map((el) => {
      el.checked = false;
      return el;
    });
    setDisplayProductArr(tempArr);
  };

  const handleFilterByQuery = (e, requiredParametersArr) => {
    try {
      // let tempArr = productArr.filter((el) => {
      //   for (const ele of requiredParametersArr) {
      //     console.log(
      //       `${el[ele]}`.toLowerCase().includes(`${e}`.toLowerCase()),
      //       "ele,el"
      //     );
      //     if (
      //       `${el[`${ele}`.toLowerCase()]}`
      //         .toLowerCase()
      //         .includes(`${e}`.toLowerCase())
      //     ) {
      //       // console.log("true")
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   }
      // });
      setQuery(e);
      // setDisplayProductArr([...tempArr]);
      // console.log([...tempArr], "...tempArr");
    } catch (err) {
      toastError(err);
    }
  };

  const product_sale_columns = [
    {
      name: "SL",
      cell: (row, index) => (
        <>
          <span onClick={(e) => handleCheckProduct(row, index, e)}>
            {row.checked == true ? (
              <i className="fa fa-check-square" />
            ) : (
              <i className="fa fa-square" />
            )}
          </span>
        </>
      ),
      sortable: true,
      width: "2%",
    },
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "7%",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      width: "25%",
    },
    {
      name: "SKU",
      selector: (row) => row?.sku,
      width: "8%",
    },

    {
      name: "Image",
      grow: 0,
      cell: (row) => (
        <img
          height="84px"
          width="56px"
          alt={row?.imageArr[0]?.imageAlt ? row?.imageArr[0]?.imageAlt : "ALT"}
          src={generateFilePath(row?.imageArr[0]?.image)}
        />
      ),
      width: "15%",
    },
    {
      name: "Action",
      width: "15%",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row.id}
            remove
            deletePath="/Product/Product-List"
            onDeleteClick={() => handleDeleteById(row._id)}
            isRedirected={true}
            onEditClick={() => {
          
            }}
            istarget={true}
            edit
            editPath={`/Product/Update-Product?slug=${row._id}`}
            // detail
            // detailClick={(e) => {
            //   e.preventDefault();
            // }}
          />
          <Tooltip title="Clone Product" placement="top" arrow>
            <button
              className="btn btn-light btn-sm rounded"
              onClick={() => {
                handleCloneProduct(row);
              }}
            >
              <i className="fa fa-clipboard"> </i>{" "}
            </button>
          </Tooltip>
        </>
      ),
    },
    {
      name: "Status",
      button: true,
      cell: (row) => (
        <Switch
          onChange={(e) => handleStatusActuve(row._id, !row.active)}
          checked={row.active}
        />
      ),
      width: "10%",
    },
    // {
    //   name: "Is Best seller",
    //   button: true,
    //   cell: (row) => <Switch checked={row.isBestSeller} />,
    //   width: "10%",
    // },

    // {
    //   name: "Clone",
    //   width: "15%",
    //   cell: (row) => (
    //     <>
    //       {/* <CustomButton btntype="button" isRedirected={true} editPath={`/Product/clone-Product`} edit ClickEvent={(e) => { e.preventDefault(); dispatch(SetPRODUCTObj(row)) }} iconName="fa-solid fa-pen-to-square" btnName="Clone Product" /> */}
    //       <CustomButton btntype="button" isLink path={`/Product/clone-Product`} edit ClickEvent={() => { dispatch(SetPRODUCTObj(row)) }} iconName="fa-solid fa-pen-to-square" btnName="Clone Product" />

    //     </>
    //   ),
    // },
  ];

  return (
    <main>
      <AddModal
        ModalBox={ModalBox}
        setModalBox={setModalBox}
        name={ModalName}
        data={ModalData}
        ModalType={ModalType}
      />
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1">Product List</h5>
                <div className="d-flex gap-3 align-items-center">
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
                <CustomButton
                  isLink
                  iconName="fa-solid fa-plus"
                  btnName="ADD NEW PRODUCT"
                  path="/Product/AddProduct"
                />
              </div>
              {displayButtons && (
                <div className="d-flex align-items-center justify-content-end mb-4">
                  <div style={{ marginLeft: 15 }}>
                    <CustomButton
                      isLink
                      iconName="fa-solid fa-minus"
                      ClickEvent={() => handleDeleteSelected()}
                      btnName="Delete Selected"
                      path="/Product/Product-List"
                    />
                  </div>
                  {/* <div style={{ marginLeft: 15 }}>
                    <CustomButton isLink iconName="fa-solid fa-plus" ClickEvent={() => handleSetSelectedAsActive()} noIcon btnName="Set selected as Active" path="/Product/Product-List" />
                  </div>
                  <div style={{ marginLeft: 15 }}>
                    <CustomButton isLink iconName="fa-solid fa-plus" ClickEvent={() => handleSetSelectedAsInActive()} noIcon btnName="Set selected as In-Active" path="/Product/Product-List" />
                  </div> */}
                  <div style={{ marginLeft: 15 }}>
                    <CustomButton
                      isLink
                      iconName="fa-solid fa-plus"
                      ClickEvent={() => handleClearSelection()}
                      noIcon
                      btnName="Clear Selection"
                      path="/Product/Product-List"
                    />
                  </div>
                </div>
              )}
              <DashboardTable>
                <DataTable
                  columns={product_sale_columns}
                  data={
                    displayProductArr && displayProductArr.length > 0
                      ? displayProductArr
                      : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
      <EditModal
        ModalBox={ModalBox}
        data={ModalData}
        setModalBox={setModalBox}
        name={ModalName}
        ModalType={ModalType}
      />
    </main>
  );
}

export default ProductList;
