import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";
import * as NEWSLETTER from "../../actions/newsLetter/NewsLetter.action";

const initialState = {
  NewsLetterArr: null,
  NewsLetterObj: null,
  loading: false,
  error: null,
};

export const NewsLetterReducer = (state = initialState, action) => {
  // console.log(action, "reduscer");
  switch (action.type) {
    case NEWSLETTER.NEWSLETTER_ADD:
      return {
        ...state,
        loading: true,
      };
    case NEWSLETTER.NEWSLETTER_ADD_SUCCESS:
      toastSuccess(action.payload);
      // console.log(action, "reducer");

      return {
        ...state,
        loading: false,
      };

    case NEWSLETTER.NEWSLETTER_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case NEWSLETTER.GET_ALL_NEWSLETTER:
      return {
        ...state,
        loading: true,
      };
    case NEWSLETTER.GET_ALL_NEWSLETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        NewsLetterArr: action.payload.data,
      };
    case NEWSLETTER.GET_ALL_NEWSLETTER_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case NEWSLETTER.DELETE_NEWSLETTER_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case NEWSLETTER.DELETE_NEWSLETTER_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case NEWSLETTER.DELETE_NEWSLETTER_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case NEWSLETTER.SET_NEWSLETTER_OBJ:
      return {
        ...state,
        loading: true,
      };
    case NEWSLETTER.SET_NEWSLETTER_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        NewsLetterObj: action.payload.data,
        loading: false,
        error: null,
      };
    case NEWSLETTER.SET_NEWSLETTER_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEWSLETTER.UPDATE_NEWSLETTER_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case NEWSLETTER.UPDATE_NEWSLETTER_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case NEWSLETTER.UPDATE_NEWSLETTER_BY_ID_FAIL:
      toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
