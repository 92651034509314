import { addShippings, deleteShipping, getShippings, updateShipping } from "../../../services/shipping.service.";


export const SHIPPING_ADD = "SHIPPING_ADD";
export const SHIPPING_ADD_SUCCESS = "SHIPPING_ADD_SUCCESS";
export const SHIPPING_ADD_FAIL = "SHIPPING_ADD_FAIL";

export const GET_ALL_SHIPPINGS = "GET_ALL_SHIPPINGS";
export const GET_ALL_SHIPPINGS_SUCCESS = "GET_ALL_SHIPPINGS_SUCCESS";
export const GET_ALL_SHIPPINGS_FAIL = "GET_ALL_SHIPPINGS_FAIL";

export const UPDATE_SHIPPING_BY_ID = "UPDATE_SHIPPING_BY_ID";
export const UPDATE_SHIPPING_BY_ID_SUCCESS = "UPDATE_SHIPPING_BY_ID_SUCCESS";
export const UPDATE_SHIPPING_BY_ID_FAIL = "UPDATE_SHIPPING_BY_ID_FAIL";

export const SET_SHIPPING_OBJ = "SET_SHIPPING_OBJ";
export const SET_SHIPPING_OBJ_SUCCESS = "SET_SHIPPING_OBJ_SUCCESS";
export const SET_SHIPPING_OBJ_FAIL = "SET_SHIPPING_OBJ_FAIL";

export const GET_SHIPPING_BY_ID = "GET_SHIPPING_BY_ID";
export const GET_SHIPPING_BY_ID_SUCCESS = "GET_SHIPPING_BY_ID_SUCCESS";
export const GET_SHIPPING_BY_ID_FAIL = "GET_SHIPPING_BY_ID_FAIL";

export const DELETE_SHIPPING_BY_ID = "DELETE_SHIPPING_BY_ID";
export const DELETE_SHIPPING_BY_ID_SUCCESS = "DELETE_SHIPPING_BY_ID_SUCCESS";
export const DELETE_SHIPPING_BY_ID_FAIL = "DELETE_SHIPPING_BY_ID_FAIL";

export const SHIPPINGADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHIPPING_ADD });
    let { data: response } = await addShippings(formData);
    if (response) {
      dispatch(SHIPPINGGET())
      dispatch({
        type: SHIPPING_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SHIPPING_ADD_FAIL, payload: err });
  }
};

export const SHIPPINGGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SHIPPINGS });
    let { data: response } = await getShippings(formData);
    console.log(response, "response356")
    if (response) {

      dispatch({
        type: GET_ALL_SHIPPINGS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SHIPPING_ADD_FAIL, payload: err });
  }
};

export const SETSHIPPINGOBJ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_SHIPPING_OBJ });
    if (formData) {
      dispatch({
        type: SET_SHIPPING_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_SHIPPING_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_SHIPPING_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const SHIPPINGUPDATE = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SHIPPING_BY_ID });
    console.log(id, "id", formData, "form");
    let { data: response } = await updateShipping(id, formData);
    if (response) {
      console.log(response);
      dispatch({
        type: UPDATE_SHIPPING_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(SHIPPINGGET());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_SHIPPING_BY_ID_FAIL, payload: err });
  }
};

export const SHIPPINGDELETE = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SHIPPING_BY_ID });
    let { data: response } = await deleteShipping(id);
    if (response) {
      console.log(response, "response");
      dispatch({
        type: DELETE_SHIPPING_BY_ID_SUCCESS,
        payload: response
      });
      dispatch(SHIPPINGGET());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_SHIPPING_BY_ID_FAIL, payload: err });
  }
};