import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";
import { DashboardTable } from "../Utility/DashboardBox";

import { useSelector, useDispatch } from "react-redux";
import {
  notificationGet,
  SetnotificationObj,
  notificationDelete,
} from "../../redux/actions/notification/Notification.action";
function Notifications() {
  const NotificationArr = useSelector(
    (state) => state.notification.NotificationArr
  );
  const dispatch = useDispatch();
  console.log(NotificationArr, "NotificationArr2");

  useEffect(() => {
    dispatch(notificationGet());
  }, []);
  const handleEdit = (obj) => {
    dispatch(SetnotificationObj(obj));
  };
  const handleDelete = (id) => {
    dispatch(notificationDelete(id));
  };
  const blog_columns = [
    {
      name: "S.NO.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "message",
      cell: (row) => <p>{row.message}</p>,
      sortable: false,
      searchable: false,
    },
    {
      name: "read",
      selector: (row) => row.isRead,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionIcon
          isRedirected={true}
          edit
          onEditClick={() => handleEdit(row)}
          editPath="/Notification/post/create"
          onDeleteClick={() => handleDelete(row._id)}
          deletePath="/Notification/post"
          remove
          Uniquekey={row._id}
        />
      ),
    },
  ];

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Notifications</h5>
                <div className="d-flex gap-3">
                  <CustomButton
                    isLink
                    iconName="fa-solid fa-plus"
                    btnName="Send Notification"
                    path="/Notification/post/create"
                  />
                  <SearchBox extraClass="bg-white" />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_columns}
                  data={
                    NotificationArr && NotificationArr.length > 0
                      ? NotificationArr
                      : []
                  }
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Notifications;
