export const countryList = [
    {
        name:"USA",
    },
    {
        name:"GERMANY",
    },
    {
        name:"UK",
    },
    {
        name:"AUSTRIA",
    },
    {
        name:"BELGIUM",
    },
    {
        name:"LUXEMBOURGE",
    },
    {
        name:"NETHERLANDS",
    },
    {
        name:"CZECH REPUBLIC",
    },
    {
        name:"DENMARK",
    },
    {
        name:"LIECHTENSTEIN",
    },
    {
        name:"FRANCE",
    },
    {
        name:"LIECHTENSTEIN",
    },
    {
        name:"SPAIN",
    },
    {
        name:"IRELAND",
    },
    {
        name:"PORTUGAL",
    },
    {
        name:"SWEDEN",
    },
    {
        name:"ESTONIA",
    },
    {
        name:"FINLAND",
    },
    {
        name:"CROATIA",
    },
    {
        name:"LATVIA",
    },
    {
        name:"LITHUANIA",
    },
    {
        name:"HUNGARY",
    },
    {
        name:"ITALY",
    },
    {
        name:"POLAND",
    },
    {
        name:"SLOVAKIA",
    },
    {
        name:"CANADA",
    },
    {
        name:"AUSTRALIA",
    },
    {
        name:"NEWZEALAND",
    },
]
export const getOrderIdSequence = (orderID) => {
    return 'AC'+String(orderID).padStart(3, 0);
  } 
  