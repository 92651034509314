import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { images } from "../Images/Images";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { generalModelStatuses } from "../Utility/constants";
import FileUpload from "../Utility/FileUpload";
import SearchBox from "../Utility/SearchBox";
import { useSelector, useDispatch } from "react-redux";
import {
  blogCategoryAdd,
  BLOGCATEGORYGet,
  BlogCategoryUpdate,
  SetBlogCategoryObj,
  BLOGCATEGORYDelete,
} from "../../redux/actions/BlogCategory/BlogCategory.actions";
import { toastError } from "../../utils/toastUtils";


function BlogCategory() {
  let dispatch = useDispatch();
  const [categoryName, setCategoryName] = useState("");
  const [image, setImage] = useState("");
  const [blogCategoriesArr, setBlogCategoriesArr] = useState([]);
  const [displayCategoryArr, setDisplayCategoryArr] = useState([]);
  const [prevBlogCategoryId, setPrevBlogCategoryId] = useState(null);

  const blogCategoryArr = useSelector(
    (state) => state.blogCategory.blogCategories
  );
  console.log(blogCategoryArr, "blogCategoryArr23");
  const blogCategoryObj = useSelector(
    (state) => state.blogCategory.blogCategoryObj
  );

  console.log(blogCategoryObj, "blogCategoryObj jsx");

  useEffect(() => {
    if (blogCategoryArr?.length) {
      setBlogCategoriesArr(blogCategoryArr);
      setDisplayCategoryArr(blogCategoryArr);
    }
  }, [blogCategoryArr]);

  const handleBlogCategoryGet = () => {
    dispatch(BLOGCATEGORYGet());
  };
  useEffect(() => {
    handleBlogCategoryGet();
  }, []);
  const handleCategoryDelete = (row) => {
    let confirm =window.confirm("Do you really want to delete this item?")
    if (confirm) {
      dispatch(BLOGCATEGORYDelete(row))
    }
  ;
  };

  useEffect(() => {
    if (blogCategoryObj) {
      setCategoryName(blogCategoryObj?.name);
      setImage(blogCategoryObj?.image);

      setPrevBlogCategoryId(blogCategoryObj?._id);
    }

    // return () => {
    //   dispatch(SetCATEGORYObj(null));
    // };
  }, [blogCategoryObj]);

  console.log(categoryName, "categoryName");

  const handleCategoryEdit = (row) => {
    dispatch(SetBlogCategoryObj(row));
  };
  const handleFileSet = (value) => {
    setImage(value);
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
    { value: "vanilla32", label: "Vanilla1" },
  ];
  const blog_category_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    // {
    //   name: "IMAGE",
    //   cell: (row) => (
    //     <img height="84px" width="56px" alt={row.image} src={row.image} />
    //   ),
    // },
    {
      name: "Category",
      selector: (row) => row.name,
    },
    {
      name: "status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      button: true,
      cell: (row) => (
        <ActionIcon
          remove
          edit
          Uniquekey={row.id}
          deletePath="/Blog/Category"
          onDeleteClick={() => {
            // console.log(row, "delete");
            handleCategoryDelete(row._id);
          }}
          isRedirected={true}
          onEditClick={() => {
            console.log(row, "asdf");
            handleCategoryEdit(row);
          }}
          editPath="/Blog/Category"
        />
      ),
    },
  ];

  const blog_category_data = [
    {
      id: "1",
      Num: "1",
      category: "Nails",
      img: `${images.brand}`,
    },
    {
      id: "2",
      Num: "2",
      category: "Eyes",
      img: `${images.brand}`,
    },
    {
      id: "3",
      Num: "3",
      category: "Face",
      img: `${images.brand}`,
    },
    {
      id: "4",
      Num: "4",
      category: "Lips",
      img: `${images.brand}`,
    },
    {
      id: "5",
      Num: "5",
      category: "Nail polish",
      img: `${images.brand}`,
    },
    {
      id: "6",
      Num: "6",
      category: "Perfect Finish box(Nail P...",
      img: `${images.brand}`,
    },
    {
      id: "7",
      Num: "7",
      category: "Foundation",
      img: `${images.brand}`,
    },
    {
      id: "8",
      Num: "8",
      category: "LIQUID SINDOOR",
      img: `${images.brand}`,
    },
    {
      id: "9",
      Num: "9",
      category: "BEAUTY POP BOX LIP COLOR",
      img: `${images.brand}`,
    },
    {
      id: "10",
      Num: "10",
      category: "LIPSTIC A & B",
      img: `${images.brand}`,
    },
  ];
  const handleSubmit = (e) => {
    e.preventDefault();
    if(`${categoryName}` == ''){
      toastError("Please fill Category Name");
      return 0;
    }

    let obj = {
      categoryName,
      image,
    };
    // console.log(selectedBrandId, "sss");
    if (blogCategoryObj?._id) {
      console.log(obj, "drt");
      dispatch(BlogCategoryUpdate(prevBlogCategoryId, obj));
    } else {
      dispatch(blogCategoryAdd(obj));
    }
  };
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row gy-4 gy-xxl-0">
            <div className="col-12 col-xxl-4">
              <h5 className="blue-1 mb-4">Add Blog Category</h5>
              <DashboardBox>
                <form action="" className="form row">
                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      CATEGORY NAME<span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={categoryName}
                      onChange={(event) => setCategoryName(event.target.value)}
                    />
                  </div>
                  {/* <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      SELECT PARENT CATEGORY <span className="red">*</span>
                    </label>
                    <Select
                      options={options}
                      value={parentCategorys}
                      onChange={(e) => setParentCategorys(e)}
                    />
                  </div> */}
                  {/* <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">Image</label>
                    <FileUpload onFileChange={handleFileSet} />
                  </div> */}
                  <div className="col-12">
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="ADD"
                      ClickEvent={handleSubmit}
                    />
                  </div>
                </form>
              </DashboardBox>
            </div>
            <div className="col-12 col-xxl-8">
              <div className="d-flex justify-content-between mb-2 align-items-center">
                <h5 className="blue-1 m-0">Category List</h5>
                <SearchBox extraClass="bg-white" />
              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_category_columns}
                  // data={blog_category_data}
                  data={
                    blogCategoriesArr && blogCategoriesArr.length > 0
                      ? blogCategoriesArr
                      : []
                  }
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default BlogCategory;
