import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useSelector, useDispatch } from "react-redux";
import {
  STATEADD,
  STATEGET,
  SETSTATEOBJ,
  STATEUPDATE,
  STATEDELETE,
} from "../../../redux/actions/State/States.actions";
function State() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [isStatus, setIsStatus] = useState(false);
  const [isUpdateState, setIsUpdateState] = useState(false);
  const [stateId, setStateId] = useState("");

  // const [isUpdateCurrency, setIisUpdateCurrency] = useState(false);
  // const [currencyId, setCurrencyId] = useState("");
  const stateObj = useSelector((state) => state.states.statesObj);

  useEffect(() => {
    if (stateObj) {
      setName(stateObj.name);
      setCountry(stateObj.country);
      setIsUpdateState(true);
      setStateId(stateObj._id);
    }
    return () => {
      dispatch(SETSTATEOBJ(null));
    };
  }, [stateObj]);
  const statesArr = useSelector((state) => state.states.states);

  useEffect(() => {
    dispatch(STATEGET());
  }, []);
  const handleEdit = (obj) => {
    dispatch(SETSTATEOBJ(obj));
  };
  const handleDelete = (id) => {
    dispatch(STATEDELETE(id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let obj = {
        name,
        country,
        isStatus,
      };
      console.log(obj, "obj23");
      if (stateId) {
        dispatch(STATEUPDATE(obj, stateId));
      } else {
        dispatch(STATEADD(obj));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const state_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      // width: "20%",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      // width: "20%",
    },
    {
      name: "Country",
      selector: (row) => row.country,
      // width: "20%",
    },
    {
      name: "Status",
      selector: (row) => row.isStatus,
    },
    // {
    //   name: "Status",
    //   button: true,
    //   cell: (row) => <Switch />,
    //   width: "20%",
    // },
    // {
    //   name: "Action",
    //   cell: (row) => <ActionIcon Uniquekey={row.id} remove edit />,
    //   // width: "20%",
    // },
    {
      name: "Action",
      cell: (row) => (
        <ActionIcon
          isRedirected={true}
          edit
          onEditClick={() => handleEdit(row)}
          editPath="/SetUp/Location"
          onDeleteClick={() => handleDelete(row._id)}
          deletePath="/SetUp/Location"
          remove
          Uniquekey={row.id}
        />
      ),
      width: "20%",
    },
  ];

  const state_data = [
    {
      id: "1",
      sl: "1",
      Name: "Montebello Vicentino",
      country: "Italy",
    },
  ];

  return (
    <div className="row">
      <div className="col-12 col-md-4">
        <DashboardBox>
          <h5 className="blue-1 mb-4">{isUpdateState ? "update" : "Add"}  State</h5>
          <form action="#" className="form row">
            <div className="col-12">
              <label>
                Name <span className="red">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-12">
              <label>COUNTRY LIST</label>
              <Select
                options={options}
                // value={name}
                onChange={(e) => setCountry(e.value)}
              />
            </div>
            <div className="col-12">
              <label>Status</label>
              <div className="d-flex">
                <div className="form-check form-check-inline d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="category-status"
                    value="option1"
                    id="category-Radio1"
                    onClick={() => setIsStatus(true)}
                  />
                  <label
                    className="form-check-label fs-14"
                    htmlFor="category-Radio1"
                  >
                    Active
                  </label>
                </div>
                <div className="form-check form-check-inline d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="category-status"
                    value="option2"
                    id="category-Radio2"
                    onClick={() => setIsStatus(false)}
                  />
                  <label
                    className="form-check-label fs-14"
                    htmlFor="category-Radio2"
                  >
                    Inactive
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 mt-2">
              <CustomButton
                isBtn
                iconName="fa-solid fa-check"
                btnName="Save"
                ClickEvent={handleSubmit}
              />
            </div>
          </form>
        </DashboardBox>
      </div>
      <div className="col-12 col-md-8">
        <DashboardTable>
          <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
            <h5 className="blue-1 m-0">State List</h5>
            <SearchBox extraClass="bg-white" />
          </div>
          <DataTable
            columns={state_columns}
            // data={state_data}
            data={statesArr && statesArr.length > 0 ? statesArr : []}
            pagination
          />
        </DashboardTable>
      </div>
    </div>
  );
}

export default State;
