import React, { useState, useEffect } from "react";
import Select from "react-select";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import ReactQuill from "react-quill"; // ES6
import FileUpload from "../Utility/FileUpload";
import QuillEditor from "../../utils/QuillEditor";
import { useSelector, useDispatch } from "react-redux";
import {
  newsletterAdd,
 
  newsletterGet,
 
  newsletterUpdate,
  SetnewsletterObj,

} from "../../redux/actions/newsLetter/NewsLetter.action";
import { generateFilePath } from "../Utility/utils";
import { PRODUCTGet } from "../../redux/actions/Product/Product.actions";
import { AddReviewApi } from "../../services/ProductReview.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
function AddReview() {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [rating, setrating] = useState(1);
  const [imageStr, setimageStr] = useState([]);
  const [newsLetterPreviousId, setNewsLetterPreviousId] = useState("");
  const [imageArr, setImageArr] = useState([{ image: "", imageAlt: "" }]);
  const [isnewsletterUpdate, setisNewsletterUpdate] = useState(false);
  const newLetterObj = useSelector((state) => state.newsLetter.NewsLetterObj);
  const productArr = useSelector((state) => state.product.products);
const [productId, setproductId] = useState("");
  const aquaticCreatures = [
    { label: 'Shark', value: 'Shark' },
    { label: 'Dolphin', value: 'Dolphin' },
    { label: 'Whale', value: 'Whale' },
    { label: 'Octopus', value: 'Octopus' },
    { label: 'Crab', value: 'Crab' },
    { label: 'Lobster', value: 'Lobster' },
  ];

  useEffect(() => {
    dispatch(PRODUCTGet('limit=5000'));
  }, []);

 
  // console.log(message, "message4");
  useEffect(() => {
    if (newLetterObj) {
      setName(newLetterObj.name);
      setMessage(newLetterObj.message);
      setNewsLetterPreviousId(newLetterObj._id);
      setisNewsletterUpdate(true);
    }
    return () => {
      dispatch(SetnewsletterObj(null));
    };
  }, [productArr]);

  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
 
        if (`${name}` == '') {
            toastError("please enter name")
            return
          }
      
          if (`${rating}` == '') {
            toastError("please enter rating")
            return
          }
      
          if (`${message}` == '') {
            toastError("please enter message")
            return
          }
      
          let obj = {
            name,
            rating,
            message,
            productId
          }
    
    try {
        let { data: res } = await AddReviewApi(obj)
  
        if (res.success) {
          toastSuccess(res.message)

          // window.location.reload();
        }
      } catch (error) {
        console.log(error);
        toastError(error)
      }
  };

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <form action="#" className="form">
            <h5 className="blue-1 mb-4">Add New Review</h5>
            <div className="row">
              <div className="col-12 col-md-8">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Review</h5>
                    <div className="col-12 mb-2">
                            <label>Products</label>
                      <Select
                               options={productArr && productArr.map(ele => ({ label: ele?.name, value: ele?._id }))}
                                      onChange={(e)=>setproductId(e.value)}
                                      />
                        </div>
                    
                    <div className="col-12 mb-2">
                        <label>
                          Rating
                          <select className="form-control bg-ligh" value={rating} onChange={(e) => { setrating(e.target.value) }}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </label>
                      </div>
                    <div className="col-12 mb-3">
                      <label>
                        Name <span className="red">*</span>
                      </label>
                      <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    {/* <div className="col-12 mb-3">
                      <label>
                        SLUG<span className="red">*</span>
                      </label>
                      <input type="text" className="form-control" />
                    </div> */}
                    <div className="col-12">
                      <label>
                        Message<span className="red">*</span>
                      </label>
                      <textarea
                        class="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                
                  </div>
                  <div className="col-12 mt-4">
                    <CustomButton
                      ClickEvent={handleSubmit}
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="Save"
                    />
                  </div>
                </DashboardBox>
              </div>
            
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddReview;
