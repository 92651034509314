import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  privacyPolicyAdd,
  privacyPolicyUpdate,
  SetprivacyPolicyObj,
} from "../../../redux/actions/PrivacyPolicy/PrivacyPolicy.action";
import { toastError, toastSuccess } from "../../Utility/ToastUtils";

import QuillEditor from "../../../utils/QuillEditor";

function AddPrivacy() {
  const dispatch = useDispatch();

  const [description, setDescription] = useState("");
  const [isUpdateprivacyPolicy, setIsUpdateprivacyPolicy] = useState(false);
  const [privacyPolicyId, setprivacyPolicyId] = useState("");

  const privacyPolicyObj = useSelector(
    (state) => state.privacyPolicy.PrivacyPolicyObj
  );
  console.log(privacyPolicyObj, "privacyPolicyObj09");
  useEffect(() => {
    if (privacyPolicyObj) {
      // setHeading(privacyPolicyObj.heading);
      console.log(privacyPolicyObj.policies, "policies");
      setDescription(privacyPolicyObj.policies);
      setprivacyPolicyId(privacyPolicyObj._id);
      setIsUpdateprivacyPolicy(true);
    }
    return () => {
      dispatch(SetprivacyPolicyObj(null));
    };
  }, [privacyPolicyObj]);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (description == "") {
        toastError("Description is mandatory");
        return;
      }
      let obj = {
        policies: description,
      };
      if (isUpdateprivacyPolicy) {
        dispatch(privacyPolicyUpdate(obj, privacyPolicyId));
      } else {
        dispatch(privacyPolicyAdd(obj));
      }
    } catch (err) {
      toastError(err);
    }
  };
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">
            {" "}
            {isUpdateprivacyPolicy ? "Update" : "Add "} Privacy
          </h5>
          <form action="#" className="form">
            <div className="row">
              <div className="col-12 col-md-8 mb-0">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Privacy Information</h5>
                    <div className="col-12">
                      <label>Write Privacy here</label>
                      <QuillEditor
                        handleChange={(event) => setDescription(event)}
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <CustomButton
                        btntype="button"
                        ClickEvent={handleSubmit}
                        isBtn
                        iconName="fa-solid fa-check"
                        btnName="Save"
                      />
                    </div>
                  </div>
                </DashboardBox>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddPrivacy;
