import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";
import * as LOGO from "../../actions/Logo/Logo.actions";

const initialState = {
  Logos: null,
  LogoObj: null,
  loading: false,
  error: null,
};

export const logoReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGO.LOGO_ADD:
      return {
        ...state,
        loading: true,
      };
    case LOGO.LOGO_ADD_SUCCESS:
      //   console.log(act);
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case LOGO.LOGO_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LOGO.GET_ALL_LOGOS:
      return {
        ...state,
        loading: true,
      };
    case LOGO.GET_ALL_LOGOS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        Logos: action.payload.data,
      };

    case LOGO.GET_ALL_LOGOS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LOGO.UPDATE_LOGO_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case LOGO.UPDATE_LOGO_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case LOGO.UPDATE_LOGO_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LOGO.DELETE_LOGO_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case LOGO.DELETE_LOGO_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case LOGO.DELETE_LOGO_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LOGO.SET_LOGO_OBJ:
      return {
        ...state,
        loading: true,
      };
    case LOGO.SET_LOGO_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        LogoObj: action.payload.data,
        loading: false,
        error: null,
      };
    case LOGO.SET_LOGO_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
