import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill"; // ES6
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { AddModal } from "../../Utility/Modal";
import { useSelector, useDispatch } from "react-redux";
import { getAllNestedCategories } from "../../../redux/actions/Category/Category.actions";
import { BrandGet } from "../../../redux/actions/Brand/brand.actions";
import {
  PRODUCTAdd,
  PRODUCTUpdate,
} from "../../../redux/actions/Product/Product.actions";
import { ATTRIBUTEGet } from "../../../redux/actions/Attribute/Attribute.actions";
import RelatedProduct from "./RelatedProduct";
import tabClick from "../../Utility/TabClick";
import QuillEditor from "../../../utils/QuillEditor";
import { generateFilePath } from "../../Utility/utils";
import moment from "moment";
import { useParams, useSearchParams } from "react-router-dom";
import { getProductById } from "../../../services/product.service";
function UpdateGeneralProduct() {
  const dispatch = useDispatch();
  const params = useParams();
  const [internalCode, setInternalCode] = useState("");
  const [productCode, setProductCode] = useState("");
  const [brandObj, setBrandObj] = useState({});

  //////product details
  const [name, setName] = useState("");
  const [sku, setSku] = useState("");
  const [productType, setProductType] = useState("");
  const [stockType, setStockType] = useState("");
  const [sizeChart, setsizeChart] = useState();

  const [despcription, setDespcription] = useState("");
  const [Specifications, setSpecifications] = useState("");
  const [availableInventory, setAvailableInventory] = useState(0);
  const [gst, setGst] = useState("");
  const [hsn, setHsn] = useState("");
  const [mrp, setMrp] = useState(0);
  const [stock, setStock] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [ships_in_days, setShips_in_days] = useState(0);
  const [returnPolicy, setReturnPolicy] = useState("");
  const [dom, setDom] = useState("");
  const [shelfLife, setShelfLife] = useState(0);
  const [imageArr, setImageArr] = useState([{ image: "", imageAlt: "" }]);
  //////size of product
  const [product_dimension_width, setProduct_Dimension_width] = useState(0);
  const [product_dimension_height, setProduct_Dimension_height] = useState(0);
  const [product_dimension_length, setProduct_Dimension_length] = useState(0);
  const [product_dimension_weight, setProduct_Dimension_weight] = useState(0);
  const [dimensionWeightType, dimensionSetweightType] = useState("")
  const [dimensionHeightType, dimensionSetHeightType] = useState("")

  const [artisan, setArtisan] = useState("");
  const [craft, setCraft] = useState("");
  const [bestSeller, setBestSeller] = useState(false);
  ///////size of packaging
  const [packaging_Length, setPackaging_Length] = useState(0);
  const [packaging_Width, setPackaging_Width] = useState(0);
  const [packaging_Height, setPackaging_Height] = useState(0);
  const [productDisplay, setproductDisplay] = useState("")

  ///////dead weight
  const [dead_weight, setDead_weight] = useState(0);
  ///////tags
  const [tags, setTags] = useState("");
  //////brand
  const [selectedBrandId, setSelectedBrandId] = useState("");
  //////category
  const [mainCategoryArr, setMainCategoryArr] = useState([]);
  const [mainAttributesArr, setMainAttributesArr] = useState([]);
  ///////////////////////////////////////////////////////////////
  const [ModalBox, setModalBox] = useState(false);
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  
  ///////////////////////////////////////////////////////////////
  const [videoLink, setVideoLink] = useState("");
  const [productImageStr, setProductImageStr] = useState("");
  const [specificationFile, setSpecificationFile] = useState("");
  const [displayAttributesArr, setDisplayAttributesArr] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaImage, setMetaImage] = useState("");
  const [status, setStatus] = useState("Publish");
  const [slug, setslug] = useState("");
  const [selectedCategoryArr, setSelectedCategoryArr] = useState([]);
  const [tabList, settabList] = useState([
    {
      tabName: "RELATED PRODUCT",
      active: false,
      render: <RelatedProduct name="Related Product" />,
    },
  ]);
  ///////////////////////////////////////////////////////////////
  const authUser = useSelector((state) => state.auth.user);
  
  const brands = useSelector((state) => state.brand.brands);
  const categoryArr = useSelector((state) => state.category.categories);
  // const productObj = useSelector((state) => state.product.productObj);
  const [productObj, setProductObj] = useState({});
  const attributes = useSelector((state) => state.attribute.attributes);
  const handleFilterChecked = (arr) => {
    if (arr.length > 0 && arr) {
      return arr.map((el) => {
        if (el.subCategoryArr && el.subCategoryArr.length > 0 && el.checked) {
          let tempArr = selectedCategoryArr;
          if (tempArr.some((el) => el != el._id)) {
            tempArr.push(el._id);
          }
          setSelectedCategoryArr([...tempArr]);
          return {
            ...el,
            categoryId: el._id,
            subCategoryArr: handleFilterChecked(el.subCategoryArr),
          };
        } else {
          if (el.checked) {
            let tempArr = selectedCategoryArr;
            if (tempArr.some((el) => el != el._id)) {
              tempArr.push(el._id);
            }
            setSelectedCategoryArr([...tempArr]);
            return { ...el, categoryId: el._id };
          }
        }
      });
    } else {
      return arr;
    }
  };
  const handleSubmit = () => {
    let cat_arr = returnSelectedCategories(mainCategoryArr);

    let tempCategoryArr = handleFilterChecked(mainCategoryArr);
    // let tempCategoryArr = handleFilterChecked(mainCategoryArr)
    let obj = {
      internalCode,
      categoryArr: cat_arr.map((el) => {
        return { categoryId: el._id };
      }),
      productCode,
      name,
      slug,
      createdBy: authUser?._id,
      sku,
      productType,
      stockType,
      description: despcription,
      specification: Specifications,
      gst,
      hsn,
      ships_in_days,
      returnPolicy,
      dom,
      shelfLife,
      imageArr,
      product_dimension_width,
      product_dimension_height,
      product_dimension_length,
      product_dimension_weight,
      packaging_Length,
      packaging_Width,
      packaging_Height,
      dimensionWeightType,
      dimensionHeightType,
      dead_weight,
      tags,
      videoLink,
      artisan,
      craft,
      productImageStr,
      specificationFile,
      status,
      metaTitle,
      metaDescription,
      metaKeyword,
      metaImage,
      sellingPrice: sellingPrice,
      brandId: selectedBrandId,
      productDisplay,
      sizeChart
    };

    if (displayAttributesArr && displayAttributesArr.length > 0) {
      obj.attributesArr = displayAttributesArr;
    } else {
      obj.attributesArr = [];
      obj.mrp = parseInt(mrp);
      obj.stock = parseInt(stock);
    }
    console.log(obj, "final Obj");
    dispatch(PRODUCTUpdate(obj, productObj._id));
  };
  useEffect(() => {
    dispatch(getAllNestedCategories());
    dispatch(BrandGet());
    dispatch(ATTRIBUTEGet());
  }, []);
  useEffect(() => {
    if (categoryArr && categoryArr.length > 0) {
      setMainCategoryArr([...categoryArr]);
    }
  }, [categoryArr]);
  useEffect(() => {
    if (attributes && attributes.length > 0) {
      setMainAttributesArr([...attributes]);
    }
  }, [attributes]);
  const returnSelectedCategories = (arr) => {
    let new_selected_arr = arr.filter((el) => el.checked);
    console.log(new_selected_arr,"categoryArrcategoryArrcategoryArr")
    let subCategories = arr.reduce(
      (acc, el) => [...acc, ...el.subCategoryArr.filter((el) => el.checked)],
      []
    );
    if (subCategories?.length) {
      return [...new_selected_arr, ...returnSelectedCategories(subCategories)];
    } else {
      return [...new_selected_arr];
    }
  };

  const handleBrandSelection = (obj) => {
    setSelectedBrandId(obj?._id);
    setBrandObj(obj);
  };
  const handleFileSet = (value, index) => {
    let tempArr = imageArr;

    tempArr[index].image = value;
    setImageArr([...tempArr]);
  };
  const handleproductImageAltEntry = (value, index) => {
    let tempArr = imageArr;
    tempArr[index].imageAlt = value;
    setImageArr([...tempArr]);
  };
  const handlePdfFileSet = (value) => {
    setSpecificationFile(value);
  };
  const handleAttributeVariantChange = (index) => {
    let tempArr = [...mainAttributesArr];
    tempArr[index].checked = !tempArr[index].checked;
    setMainAttributesArr(tempArr);
  };
  const handleInnerAttributeVariantChange = (index, innerIndex) => {
    let tempArr = [...mainAttributesArr];
    tempArr[index].attributeValueArr[innerIndex].checked =
      !tempArr[index].attributeValueArr[innerIndex].checked;
    setMainAttributesArr([...tempArr]);
    generateCombinations(tempArr);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {

  let slug =   searchParams.get("slug")
    console.log(slug,"slugslug")
    handleGetproducts(slug);
  }, [searchParams]);

  
  const handleGetproducts = async (slug) => {
    try {
      let { data: res } = await getProductById(slug);
      if (res.data) {
        // console.log(res.data);
        setProductObj(res.data);
      }
    } catch (err) {

      // console.error(err);
    }

  }
  ////////////p n c generator
  const generateCombinations = (tempattributeArr) => {
    console.log(tempattributeArr, "tempattributeArr");
    const combine = ([head, ...[headTail, ...tailTail]]) => {
      // https://stackoverflow.com/a/57015870
      if (!headTail) {
        return head?.map((el) => ({
          name: el.name,
          attributeValueArr: [...el.attributeValueArr],
        }));
      }
      const combined = headTail?.reduce((acc, x) => {
        return acc.concat(
          head.map((h) => ({
            name: `${h.name}-${x.name}`,
            attributeValueArr: [...h.attributeValueArr, ...x.attributeValueArr],
            price: 0,
          }))
        );
      }, []);
      return combine([combined, ...tailTail]);
    };
    let finalArr = [];
    tempattributeArr.forEach((el) => {
      if (el.checked) {
        let tempArr = el.attributeValueArr.filter((elx) => elx.checked);
        console.log(tempArr, "tempArr");
        if (tempArr.length) {
          finalArr.push(
            tempArr.map((el) => ({
              name: el.name,
              attributeValueArr: [
                {
                  attributeId: el._id,
                },
              ],
              price: 0,
            }))
          );
        }
      }
    });
    console.log(finalArr, "finalArr");
    if (finalArr.length) {
      finalArr = combine(finalArr);
    } else {
      setDisplayAttributesArr([]);
    }

    // console.log(finalArr, 'FINAL ARR')
    // finalArr
    if (finalArr.length) {
      setDisplayAttributesArr([
        ...finalArr.map((el) => ({
          ...el,
          price: 0,
          discount: 0,
          currentStock: 0,
          stockNo: 0,
        })),
      ]);
    }
  };
  const handleDisplayAttributesArrChange = (e, index, key) => {
    let tempArr = [...displayAttributesArr];
    tempArr[index][key] = e;
    setDisplayAttributesArr([...tempArr]);
  };

  useEffect(() => {
    if (productObj && productObj?.name) {
      console.log(productObj, "productObj");
      setName(productObj?.name);
      setInternalCode(productObj?.internalCode);
      setProductCode(productObj?.productCode);
      setSku(productObj?.sku);
      setDespcription(productObj?.description ? productObj?.description : "");
      setSpecifications(
        productObj?.specification ? productObj?.specification : ""
      );
      setslug(productObj?.slug)
      setAvailableInventory(productObj?.availableInventory);
      setGst(productObj?.gst);
      setHsn(productObj?.hsn);
      setMrp(productObj?.mrp);
      setStock(productObj?.stock);
      setProductType(productObj?.productType);
      setStockType(productObj?.stockType);
      setSellingPrice(productObj?.sellingPrice);
      setShips_in_days(productObj?.ships_in_days);
      setReturnPolicy(productObj?.returnPolicy);
      setDom(productObj?.dom);
      setShelfLife(productObj?.shelfLife);
      setProduct_Dimension_width(productObj?.product_dimension_width);
      setProduct_Dimension_height(productObj?.product_dimension_height);
      setProduct_Dimension_length(productObj?.product_dimension_length);
      setProduct_Dimension_weight(productObj?.product_dimension_weight);
      setPackaging_Length(productObj?.packaging_Length);
      setPackaging_Width(productObj?.packaging_Width);
      setPackaging_Height(productObj?.packaging_Height);
      setDead_weight(productObj?.dead_weight);
      dimensionSetHeightType(productObj?.dimensionHeightType);
      dimensionSetweightType(productObj?.dimensionWeightType);
      setDead_weight(productObj?.dead_weight);
      setTags(productObj?.tags);
      setMetaTitle(productObj?.metaTitle);
      setMetaDescription(productObj?.metaDescription);
      setMetaKeyword(productObj?.metaKeyword);
      setMetaImage(productObj?.metaImage);
      setImageArr(productObj?.imageArr);
      setVideoLink(productObj?.videoLink);
      setStatus(productObj?.status);
      setArtisan(productObj?.artisan);
      setCraft(productObj?.craft);
      setsizeChart(productObj?.sizeChart);
      setproductDisplay(productObj?.productDisplay);
    }
  }, [productObj]);

  useEffect(() => {
    if (brands && productObj) {
      setSelectedBrandId(productObj?.brandId);
      let tempBrandObj = brands.find((el) => el._id == productObj?.brandId);
      if (tempBrandObj) {
        tempBrandObj.label = tempBrandObj?.name;
        tempBrandObj.value = tempBrandObj?._id;
        setBrandObj(tempBrandObj);
      }
    }
  }, [productObj, brands]);

  useEffect(() => {
    if (
      attributes &&
      attributes.length > 0 &&
      productObj &&
      productObj.attributesArr &&
      productObj.attributesArr.length > 0
    ) {
      let temp = handleAttributesArrSelectionOnInit(
        attributes,
        productObj?.attributesArr
      );
      setDisplayAttributesArr(productObj.attributesArr);
    }
  }, [productObj, attributes]);
  const handleAttributesArrSelectionOnInit = (
    existingAttributesArr,
    attributesArrFromDb
  ) => {
    for (const el of existingAttributesArr) {
      if (el.attributeValueArr && el.attributeValueArr.length > 0) {
        for (const ele of el.attributeValueArr) {
          if (
            attributesArrFromDb.some((elx) =>
              elx.attributeValueArr.some((elm) => elm.attributeId == ele._id)
            )
          ) {
            ele.checked = true;
            el.checked = true;
          }
        }
      }
    }
    return existingAttributesArr;
  };

  const handleCategorySelectOnInit = (
    selectedCategoryArrFromDB,
    categoryArr
  ) => {
    let tempArr = categoryArr.map((el) => {
      if (selectedCategoryArrFromDB?.length >  0  && selectedCategoryArrFromDB.some((ele) => ele.categoryId == el._id)) {
        el.checked = true;
      }
      if (el.subCategoryArr) {
        handleCategorySelectOnInit(
          selectedCategoryArrFromDB,
          el.subCategoryArr
        );
      }
      return el;
    });
  };
  useEffect(() => {
    if (productObj && categoryArr) {
      handleCategorySelectOnInit(productObj.categoryArr, categoryArr);
    }
  }, [categoryArr, productObj]);

  // const handleDiscountTypeChange = (obj) => {
  //   setSelectedDiscountType(obj.value);
  // };

  const options = [
    { value: "chocolate", label: "CGST" },
    { value: "strawberry", label: "IGST" },
    { value: "vanilla", label: "SGST" },
  ];

  const discount = [
    { value: "amount", label: "Amount" },
    { value: "percentage", label: "Percentage" },
  ];

  const handleRenderNestedCategory = (arr, id, value) => {
    let tempArr = arr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          handleRenderNestedCategory(el.subCategoryArr, id, value);
        } else {
          return el;
        }
      }
    });
    return tempArr;
  };

  const handleImageObjAdd = () => {
    let tempArr = imageArr;
    tempArr.push({ image: "", imageAlt: "" });
    setImageArr([...tempArr]);
  };
  const handleImageObjRemove = () => {
    let tempArr = imageArr.filter((el, index) => index != imageArr.length - 1);
    setImageArr([...tempArr]);
  };

  const handleNestedCategoryCheckBoxEvent = (id, value) => {
    let tempCategoryArr = categoryArr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          el.subAttributesArr = handleRenderNestedCategory(
            el.subCategoryArr,
            id,
            value
          );
          return el;
        } else {
          return el;
        }
      }
    });
    setMainCategoryArr([...tempCategoryArr]);
  };

  const handleRenderCheckboxCategory = (obj) => {
    return (
      <div
        key={obj._id}
        className="col-12 mb-3"
        style={{ marginLeft: `${obj.level + 5}px` }}
      >
        <input
          className="form-check-input pointer"
          checked={obj.checked}
          value={tags}
          onChange={(event) =>
            handleNestedCategoryCheckBoxEvent(obj._id, event.target.checked)
          }
          type="checkbox"
        />
        <label style={{ paddingLeft: 5 }}>{obj.name}</label>
        {obj.checked &&
          obj.subCategoryArr &&
          obj.subCategoryArr.length > 0 &&
          obj.subCategoryArr.map((el) => {
            return handleRenderCheckboxCategory(el);
          })}
      </div>
    );
  };

  return (
    <form className="form">
      <div className="row gy-4 gy-xxl-0">
        <div className="col-12 col-xxl-8">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Product Information</h5>
              <div className="border-bottom pb-3 mb-4 row">
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Produc Name <span className="red">*</span>
                  </label>
                  <input
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Internal Code <span className="red">*</span>
                  </label>
                  <input
                    value={internalCode}
                    onChange={(event) => setInternalCode(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Slug <span className="red">*</span>
                  </label>
                  <input
                    value={slug}
                    onChange={(event) => setslug(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <div className="d-flex align-items-baseline justify-content-between">
                    <label>BRAND</label>
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-circle-plus"
                      btnName="ADD NEW"
                      changeClass="green fs-12 border-0 bg-white"
                      ClickEvent={(e) => {
                        e.preventDefault();
                        setModalBox(true);
                        setModalType("addBrandModal");
                        setModalName("Create Brand");
                      }}
                    />
                    <AddModal
                      ModalBox={ModalBox}
                      setModalBox={setModalBox}
                      name={ModalName}
                      ModalType={ModalType}
                    />
                  </div>
                  <Select
                    onChange={handleBrandSelection}
                    value={brandObj}
                    defaultValue={selectedBrandId}
                    options={
                      brands && brands.length > 0
                        ? brands.map((el) => ({
                            ...el,
                            label: el.name,
                            value: el._id,
                          }))
                        : []
                    }
                  />
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    GST <span className="red">*</span>
                  </label>
                  <input
                    value={gst}
                    onChange={(event) => setGst(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    HSN CODE<span className="red">*</span>
                  </label>
                  <input
                    value={hsn}
                    onChange={(event) => setHsn(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Date of manufacturing<span className="red">*</span>
                  </label>
                  <input
                    value={moment(dom).format("YYYY-MM-DD")}
                    onChange={(event) => setDom(event.target.value)}
                    type="date"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    SKU<span className="red">*</span>
                  </label>
                  <input
                    value={sku}
                    onChange={(event) => setSku(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Product Type<span className="red">*</span>
                  </label>
                  <input value={productType} onChange={(event) => setProductType(event.target.value)} type="text" className="form-control" />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                   Product Display<span className="red">*</span>
                  </label>
                  <select className="form-control" value={productDisplay} onChange={(e)=>{setproductDisplay(e.target.value)}}>
                    <option value="">None </option>
                    <option value="arrival">New Arrival</option>
                    <option value="bestseller">BestSeller</option>
                  </select>
                </div>
              </div>

              <div className="border-bottom pb-3 mb-4 row">
                <div className="col-12 mb-0">
                  <h5 className="blue-1 mb-4">
                    Product Shipping & Dimentions Info{" "}
                    <span className="red">*</span>
                  </h5>
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Ships In (Days)<span className="red">*</span>
                  </label>
                  <input
                    value={ships_in_days}
                    onChange={(event) => setShips_in_days(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Packaging Length<span className="red">*</span>
                  </label>
                  <input
                    value={packaging_Length}
                    onChange={(event) =>
                      setPackaging_Length(event.target.value)
                    }
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Packaging Width<span className="red">*</span>
                  </label>
                  <input
                    value={packaging_Width}
                    onChange={(event) => setPackaging_Width(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Packaging Height<span className="red">*</span>
                  </label>
                  <input
                    value={packaging_Height}
                    onChange={(event) =>
                      setPackaging_Height(event.target.value)
                    }
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Dead weight<span className="red">*</span>
                  </label>
                  <input
                    value={dead_weight}
                    onChange={(event) => setDead_weight(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>
                  Attributes<span className="red">*</span>
                </label>
                {mainAttributesArr &&
                  mainAttributesArr.length > 0 &&
                  mainAttributesArr.map((el, index) => {
                    return (
                      <div key={el._id}>
                        <input
                          checked={el.checked}
                          onChange={(event) =>
                            handleAttributeVariantChange(index)
                          }
                          className="form-check-input pointer"
                          type="checkbox"
                        />
                        <label style={{ paddingLeft: 5 }}>{el.name}</label>
                        {el.checked && (
                          <>
                            {el.attributeValueArr.length > 0 &&
                              el.attributeValueArr.map((ele, index2) => {
                                return (
                                  <div key={index2} style={{ marginLeft: 20 }}>
                                    <input
                                      checked={ele.checked}
                                      onChange={(event) =>
                                        handleInnerAttributeVariantChange(
                                          index,
                                          index2
                                        )
                                      }
                                      className="form-check-input pointer"
                                      type="checkbox"
                                    />
                                    <label style={{ paddingLeft: 5 }}>
                                      {ele.label}
                                    </label>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </div>
                    );
                  })}
              </div>
              {displayAttributesArr && displayAttributesArr.length > 0 && (
                <div className="col-12 mb-0">
                  <h5 className="blue-1 mb-4">
                    Product Variants Info <span className="red">*</span>
                  </h5>
                </div>
              )}
              {displayAttributesArr &&
                displayAttributesArr.length > 0 &&
                displayAttributesArr.map((el, index) => {
                  return (
                    <div className="col-12 col-md-12" key={index}>
                      <div className="d-flex align-items-baseline justify-content-between">
                        <label>
                          {el.name}
                          <span className="red">*</span>
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6 mb-3">
                          <label>Variant Price</label>
                          <input
                            value={el?.price}
                            onChange={(e) =>
                              handleDisplayAttributesArrChange(
                                e.target.value,
                                index,
                                "price"
                              )
                            }
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                          <label>Variant Stock</label>
                          <input
                            value={el?.currentStock}
                            onChange={(e) =>
                              handleDisplayAttributesArrChange(
                                e.target.value,
                                index,
                                "currentStock"
                              )
                            }
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div className="border-bottom pb-3 mb-4 row">
                <h5 className="blue-1 mb-4">
                  Tags (Comma Separated) <span className="red">*</span>{" "}
                </h5>
                <div className="col-12 mb-3">
                  <input
                    value={tags}
                    onChange={(event) => setTags(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Product Category <span className="red">*</span>
              </h5>
              {mainCategoryArr &&
                mainCategoryArr.length > 0 &&
                mainCategoryArr.map((el) => {
                  return handleRenderCheckboxCategory(el);
                })}
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <div className="col-12 mb-0">
                <h5 className="blue-1 mb-4">Product (Dimentions) Weight Height Info </h5>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Weight</label>
                <input value={product_dimension_weight} onChange={(event) => setProduct_Dimension_weight(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Weight Type {dimensionWeightType}</label>
                <select className="form-control" value={dimensionWeightType} onChange={(e)=>{dimensionSetweightType(e.target.value)}}>
                  <option value="grams">grams</option>
                  <option value="kg">Kg</option>
                </select>
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Length </label>
                <input value={product_dimension_length} onChange={(event) => setProduct_Dimension_length(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Breadth </label>
                <input value={product_dimension_width} onChange={(event) => setProduct_Dimension_width(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Height </label>
                <input type="text" value={product_dimension_height} onChange={(event) => setProduct_Dimension_height(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Dimensions Type</label>
                <select className="form-control" value={dimensionHeightType} onChange={(e)=>{dimensionSetHeightType(e.target.value)}}>
                  <option value="cm">Cm</option>
                  <option value="inches">Inches</option>
                </select>
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Price Info And Stock <span className="red">*</span>
              </h5>
              <>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    SELLING PRICE<span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    min={0}
                    value={sellingPrice}
                    onChange={(event) => setSellingPrice(event.target.value)}
                    className="form-control"
                  />
                </div>
                {displayAttributesArr && !(displayAttributesArr.length > 0) && (
                  <>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        MRP<span className="red">*</span>
                      </label>
                      <input
                        value={mrp}
                        onChange={(event) => setMrp(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        STOCK<span className="red">*</span>
                      </label>
                      <input
                        value={stock}
                        onChange={(event) => setStock(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </>
                )}
                  <div className="col-12 col-md-6 mb-3">
                  <label>
                    Stock Type
                  </label>
                  <input value={stockType} onChange={(event) => setStockType(event.target.value)} type="text" className="form-control" />
                </div>
              </>
            </div>
                
            <div className="col-12 mb-3">
                <label>Size Chart</label>
                <FileUpload onFileChange={(val) => setsizeChart(val)} />

              </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Artisan <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor
                  value={artisan}
                  handleChange={(e) => setArtisan(e)}
                />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Craft <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor value={craft} handleChange={(e) => setCraft(e)} />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Return Policy <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor
                  value={returnPolicy}
                  handleChange={(e) => setReturnPolicy(e)}
                />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Description <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor
                  value={despcription}
                  handleChange={(e) => setDespcription(e)}
                />
                {/* <ReactQuill theme="snow" onChange={(e) => setDespcription(e)} /> */}
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Specifications <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor
                  value={Specifications}
                  handleChange={(e) => setSpecifications(e)}
                />
              </div>
            </div>
            <div className="row">
              <h5 className="blue-1 mb-4">
                SEO info <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <label>META TITLE</label>
                <input
                  onChange={(e) => setMetaTitle(e.target.value)}
                  value={metaTitle}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col-12 mb-3">
                <label>META DESCRIPTION</label>
                <textarea
                  onChange={(e) => setMetaDescription(e.target.value)}
                  value={metaDescription}
                  name="META DESCRIPTION"
                  className="form-control"
                  rows="3"
                ></textarea>
              </div>
              <div className="col-12 mb-3">
                <label>META KEYWORD</label>
                <textarea
                  onChange={(e) => setMetaKeyword(e.target.value)}
                  value={metaKeyword}
                  name="META KEYWORD"
                  className="form-control"
                  rows="3"
                ></textarea>
              </div>
              <div className="col-12 mb-3">
                <label>META IMAGE (300X300)PX</label>
                <br />
                <br />
                <img
                  src={
                    `${metaImage}`.includes("base64")
                      ? metaImage
                      : generateFilePath(metaImage)
                  }
                  style={{ height: 80 }}
                />
                <br />
                <br />
                <FileUpload onFileChange={(val) => setMetaImage(val)} />
              </div>
              {/* <div className="col-12">
                <CustomButton
                  btntype="button"
                  ClickEvent={handleSubmit}
                  isBtn
                  iconName="fa-solid fa-check"
                  btnName="Save"
                />
              </div> */}
            </div>
          </DashboardBox>
        </div>
        <div className="col-12 col-xxl-4">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-3">
                Product Image Info <span className="red">*</span>
              </h5>
              <div className="row">
                <div className="col-2 me-5">
                  <CustomButton
                    btntype="button"
                    ClickEvent={handleImageObjAdd}
                    isBtn
                    noIcon
                    btnName="+"
                  />
                </div>
                <div className="col-2">
                  <CustomButton
                    btntype="button"
                    ClickEvent={handleImageObjRemove}
                    isBtn
                    noIcon
                    btnName="-"
                  />
                </div>
              </div>
              <div className="col-12 mb-3">
                <label>
                  Product Image<span className="red">*</span>
                </label>
                {imageArr &&
                  imageArr.length > 0 &&
                  imageArr.map((el, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          marginTop: 20,
                          borderBottom: "grey 1px solid",
                          paddingBottom: 15,
                        }}
                        className="row"
                      >
                        <div className="col-12">
                          <img
                            src={
                              `${el.image}`.includes("base64")
                                ? el.image
                                : generateFilePath(el.image)
                            }
                            style={{ height: 80 }}
                          />

                          <FileUpload
                            onFileChange={(val) => handleFileSet(val, index)}
                          />
                        </div>
                        <div className="col-12">
                          <label>
                            Product Image alt<span className="red">*</span>
                          </label>
                          <input
                            onChange={(e) =>
                              handleproductImageAltEntry(e.target.value, index)
                            }
                            value={el?.imageAlt}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="row">
              <h5 className="blue-1 mb-3">Others Info</h5>
              <div className="col-12 mb-3">
                <label>
                  STATUS<span className="red">*</span>
                </label>
                <div className="d-flex">
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input
                      onChange={(e) => setStatus("Publish")}
                      checked={status == "Publish"}
                      className="form-check-input pointer"
                      type="radio"
                      name="product-status"
                      value="option1"
                      id="product-publish"
                    />
                    <label
                      className="form-check-label fs-14 pointer"
                      htmlFor="product-publish"
                    >
                      Publish
                    </label>
                  </div>
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input
                      onChange={(e) => setStatus("Pending")}
                      checked={status == "Pending"}
                      className="form-check-input pointer"
                      type="radio"
                      name="product-status"
                      value="option2"
                      id="product-pending"
                    />
                    <label
                      className="form-check-label fs-14 pointer"
                      htmlFor="product-pending"
                    >
                      Pending
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
              </div>
              {/* <div className="col-12 mb-3">
                <label>
                  IS BEST SELLER<span className="red">*</span>
                </label>
                <div className="d-flex">
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input
                      onChange={(e) => setBestSeller(true)}
                      checked={bestSeller == true}
                      className="form-check-input pointer"
                      type="radio"
                      name="product-status1"
                      value="option1"
                      id="bestSeller1"
                    />
                    <label
                      className="form-check-label fs-14 pointer"
                      htmlFor="bestSeller1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input
                      onChange={(e) => setBestSeller(false)}
                      checked={bestSeller == false}
                      className="form-check-input pointer"
                      type="radio"
                      name="product-status1"
                      value="option2"
                      id="bestSeller"
                    />
                    <label
                      className="form-check-label fs-14 pointer"
                      htmlFor="bestSeller"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div> */}
            </div>
          </DashboardBox>
        </div>
      </div>
    </form>
  );
}

export default UpdateGeneralProduct;
